const API_BASE_URL = "https://portalized-0aad6191bbad.herokuapp.com"; // Replace with your actual backend URL

  // Helper function for POST requests
  export const postRequest = async (endpoint, body,token=null) => {

    const headers = { "Content-Type": "application/json" };
    if (token) headers["Authorization"] = `Bearer ${token}`;

      const response = await fetch(`${API_BASE_URL}/${endpoint}/`, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });

        let data;
        try {
          data = await response.json();
        } catch (jsonError) {
          throw new Error("Server error: Unable to parse response");
        }
        

        if (!response.ok) throw new Error(data?.error || data?.message||data?.email ||data?.current_password|| "Something went wrong");
    
      return data;

      
  
  };


export const getRequest = async (endpoint, token = null) => {
  const headers = { "Content-Type": "application/json" };
  if (token) headers["Authorization"] = `Bearer ${token}`;

  const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
    method: "GET",
    headers,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || "Request failed");
  }

  return response.json();
};


export const patchRequest = async (endpoint, body, token = null) => {
  const headers = { "Content-Type": "application/json" };
  if (token) headers["Authorization"] = `Bearer ${token}`;

  const response = await fetch(`${API_BASE_URL}/${endpoint}/`, {
    method: "PATCH",
    headers,
    body: JSON.stringify(body),
  });

  const data = await response.json();
   if (!response.ok) throw new Error(data?.error ||data?.message || "Something went wrong");

  return data;
};


export const putRequest = async (endpoint, body, token = null) => {
  const headers = { "Content-Type": "application/json" };
  if (token) headers["Authorization"] = `Bearer ${token}`;

  const response = await fetch(`${API_BASE_URL}/${endpoint}/`, {
    method: "PUT",
    headers,
    body: JSON.stringify(body),
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data?.error ||data?.message || "Something went wrong");

  return data;
};


export const deleteRequest = async (endpoint, token = null) => {
  const headers = { "Content-Type": "application/json" };
  if (token) headers["Authorization"] = `Bearer ${token}`;

  const response = await fetch(`${API_BASE_URL}/${endpoint}/`, {
    method: "DELETE",
    headers,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || "Failed to delete");
  }

  return response;
};