import {postRequest} from './index'

export const registerUser = async ({ email, password,role, first_name,last_name }) => {


  return postRequest("auth/register", {
    email,
    password,
    role: "general_user",
    first_name,
    last_name,
  });
};

// Login User
export const loginUser = async ({ email, password }) => {
  return postRequest("auth/login", { email, password });
};

// Refresh Token
export const refreshToken = async (refreshToken) => {
  return postRequest("auth/token/refresh", { refresh: refreshToken });
};