import { getRequest, putRequest } from "./index";

export const getProductReviews = async (productId) => {
  return getRequest(`reviews/product/${productId}/`);
};


export const submitReview = async (productId, { rating, review_text, token }) => {
  return putRequest(`reviews/add/${productId}`, {product:productId, rating, review_text }, token);
};


