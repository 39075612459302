import React, { useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Logo from './logo';
import { FiShoppingCart } from "react-icons/fi";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchCart, removeFromCart, updateQuantity } from '../store/slices/cartSlice';
import { MdDelete } from 'react-icons/md';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { FaAngleDown } from "react-icons/fa6";
import { useAuth } from "../providers/AuthProvider";
import { BiUser } from 'react-icons/bi';




const Header = () => {
    return (
        <header className="px-4 py-8 text-white bg-black md:px-20">
            <div className="flex items-center justify-between gap-2 mx-auto">
                <div className="flex">
                    <Logo />
                </div>
                <nav className="hidden lg:flex sm:space-x-2 lg:space-x-4 xl:space-x-8 whitespace-nowrap">
                    <NavLink
                        to="/"
                        className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                    >
                        Home
                    </NavLink>
                    <NavLink
                        to="/about-us"
                        className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                    >
                        About Us
                    </NavLink>
                    <NavLink
                        to="/mobile-app"
                        className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                    >
                        Mobile App
                    </NavLink>
                    <NavLink
                        to="/watch"
                        className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                    >
                        Watch
                    </NavLink>
                    <NavLink
                        to="/listen"
                        className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                    >
                        Listen
                    </NavLink>
                    <NavLink
                        to="/shop"
                        className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                    >
                        Shop
                    </NavLink>
                    <NavLink
                        to="/contact-us"
                        className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                    >
                        Contact Us
                    </NavLink>
                </nav>
                <div className="flex space-x-6">
                    <Cart />
                    <User />
                    <button
                    type="button"
                    className="text-white lg:hidden"
                    onClick={() => {
                        const menu = document.getElementById('mobile-menu');
                        menu.classList.toggle('!flex');
                        
                    }}
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
                </div>
                
            </div>

            
            <div id="mobile-menu" className="flex-col hidden px-4 py-6 space-y-6 text-white bg-black ">
                <NavLink
                    to="/"
                    className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                >
                    Home
                </NavLink>
                <NavLink
                    to="/about-us"
                    className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                >
                    About Us
                </NavLink>
                <NavLink
                    to="/mobile-app"
                    className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                >
                    Mobile App
                </NavLink>
                <NavLink
                    to="/watch"
                    className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                >
                    Watch
                </NavLink>
                <NavLink
                    to="/listen"
                    className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                >
                    Listen
                </NavLink>
                <NavLink
                    to="/shop"
                    className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                >
                    Shop
                </NavLink>
                <NavLink
                    to="/contact-us"
                    className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}
                >
                    Contact Us
                </NavLink>
            </div>
        </header>
    );
};

export default Header;





function Cart() {
      const { token } = useAuth(); 

   

    const cartItems = useSelector((state) => state.cart.cartItems);
    const dispatch = useDispatch();
      const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);
       const displayCount = totalItems > 9 ? "9+" : totalItems; 

    const handleRemoveItem = (id) => {
        dispatch(removeFromCart({ itemId:id, token}));
    };

    const handleUpdateQuantity = async (id, newQuantity) => {
      if (newQuantity <= 0) {
          dispatch(removeFromCart({ itemId:id, token}));
        
      } else {
          dispatch(updateQuantity({itemId: id, quantity: newQuantity, token })); 
      }
    };


       useEffect(() => {
    if (token) {
      dispatch(fetchCart(token)); // ✅ Fetch cart if user is logged in
    }
  }, [token, dispatch]);
    return (



        <Popover>
             <PopoverButton className="relative mt-2 *:focus:outline-none">
        {/* ✅ Red Badge for Total Items in Cart */}
        {totalItems > 0 && (
          <span className="absolute -top-3.5 -right-3 bg-red-600 text-white text-xs font-bold px-2.5 py-1 rounded-full">
            {displayCount}
          </span>
        )}
        <FiShoppingCart size={24} className="text-white" />
      </PopoverButton>
            <PopoverPanel
                transition
                anchor="bottom center"
                className="p-4 rounded-xl bg-[#090909] text-white transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0 w-full sm:w-[684px]"
            >
                <div className="w-full">
                    <div className="flex items-center justify-between">
                        <h6 className="font-light h6">{`Your Cart (${cartItems.length})`}</h6>
                        <Link to="/checkout" className="b7">
                            View Cart
                        </Link>
                    </div>

                    <div className="mt-4 space-y-4 ">
                        {cartItems.map((item) => (
                            <div
                                key={item.id}
                                className="flex items-start gap-4 bg-[#121212] p-3 rounded-lg"
                            >

                                <img
                                    src={item.product_image}
                                    alt={item.product_name}
                                    className="object-cover w-20 h-20 rounded"
                                />


                                <div className="flex-1">
                                    <p className="h8">{item.product_name}</p>
                                    <p className="mt-1 h7">${item.price_at_purchase}</p>
                                    <button
                                        onClick={() => handleRemoveItem(item.id)}
                                        className="flex items-center gap-1"
                                    >
                                        <MdDelete /> Delete
                                    </button>
                                </div>


                                <div className="flex items-center gap-3 mt-5 bg-black rounded-md ">
                                    <button
                                        onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)}
                                        className="px-4 py-2 rounded"
                                    >
                                        -
                                    </button>
                                    <span className="text-sm bg-bla">{item.quantity}</span>
                                    <button
                                        onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}
                                        className="px-4 py-2 rounded"
                                    >
                                        +
                                    </button>
                                </div>


                            </div>
                        ))}
                    </div>
                    <div className='flex items-center justify-center p-4'>
                        <Link to={'/checkout'} className='btn-link max-w-fit !px-10' >
                            Continue to Checkout
                        </Link>
                    </div>
                </div>
            </PopoverPanel>
        </Popover>
    )
}



function User() {

    // const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    // const user = useSelector((state) => state.user.user);

    const { user, token, logout } = useAuth(); // ✅ Use AuthProvider
   
    

    return (
        <>
            {token && user ? ( // ✅ Check if user is logged in
                <Profile user={user} logout={logout} />
            ) : (
                <>
                    <Link to="/sign-up" className="hidden btn-link-inverted sm:block">Register</Link>
                    <Link to="/login" className="btn-link">Login</Link>
                </>
            )}
        </>
    );
}




function Profile({ user }) {
     const { logout } = useAuth();
      const navigate = useNavigate();

    const handleLogout = () => {
        logout(); // ✅ Use AuthProvider's logout function
        navigate('/login');
    };


    return (
        <Menu>
            <MenuButton className="inline-flex items-center gap-2">
                <div className='flex items-center gap-2 b9 font-extralight '>
                    <div className='flex items-center justify-center w-10 h-10 overflow-hidden border rounded-full'>
                      {user.profile_picture!==null?
                       <img src={user.profile_picture} alt="profile"/>:
                      <BiUser size={40} className="flex items-center justify-center"/>}  
                    </div>

                    <FaAngleDown />
                </div>
            </MenuButton>

            <MenuItems
                transition
                anchor="bottom end"
                className="w-56 origin-top-right rounded-xl border border-white/5 bg-black p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
            >
                <MenuItem>
                    <div class="px-4 py-3 border-b">
                        <span class="block text-sm ">{user.name}</span>
                        <span class="block text-sm truncate text-primary-100 ">{user.email}</span>
                    </div>
                </MenuItem>
                <MenuItem>
                    <Link className='group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10' to={'/user/profile'}>
                        Profile
                    </Link>
                </MenuItem>
               
               
                <MenuItem>
                    <Link className='group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10' to={'/user/orders'}>
                        Orders
                    </Link>
                </MenuItem>
                <MenuItem>
                    <button onClick={handleLogout} className='text-red-600 group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10'>
                        Logout
                    </button>
                </MenuItem>
            </MenuItems>
        </Menu>
    );
}