import React from 'react'
import { Link } from 'react-router-dom'

const PodcastCard = ({ action = false, name, id, cover_image, description, owner, views = '128k', followers = '12.6k', layout }) => {

    // if (layout == 'list') {
    //     return (
    //         <Link to={id} className="flex w-full gap-2 overflow-hidden bg-primary-200 rounded-xl">
    //             <div className="relative h-[130px] w-[130px] overflow-hidden ">
    //                 <img
    //                     src={imgsrc}
    //                     alt={name}
    //                     className="object-cover w-full h-full"
    //                 />
    //             </div>
    //             <div className='py-2'>
    //                 <div className='flex flex-col justify-between h-full '>
    //                     <h6 className='h9'>{name}</h6>
    //                     <p className='flex items-center gap-2 b8'>
    //                         {views} Views
    //                     </p>

    //                     <div className='flex items-center justify-between'>
    //                         <div className='flex items-center gap-2'>
    //                             <div className='bg-primary-150 w-[32px] h-[32px] rounded-full overflow-hidden'>
    //                                 <img src='/assets/user.avif' alt={owner} className='' />
    //                             </div>
    //                             <div>
    //                                 <p className='b7'>{owner}</p>
    //                             </div>
    //                         </div>
    //                         {
    //                             action && <button> <BsThreeDotsVertical size={34} /></button>
    //                         }
    //                     </div>
    //                 </div>

    //             </div>
    //         </Link>
    //     )
    // }



    return (
        <Link to={`/podcast/${id}`} className='overflow-hidden bg-black rounded-3xl'>
            <div className='relative h-[250px] overflow-hidden'>
                <img src={cover_image} alt={name} className="object-fill " />
            </div>
            <div className='flex flex-col gap-3 p-4'>
                <div className='flex flex-col gap-2'>
                    <h6 className='h7'>{name}</h6>
                    <p className='flex items-center gap-2 b7'>
                        {views} Views
                    </p>
                </div>
                <p className='text-primary-100 c8'>{description}</p>
               
            </div>
        </Link>
    )
}

export default PodcastCard
