import React, { useEffect, useRef, useState } from 'react'
import { MdFullscreen } from 'react-icons/md'
import { TbRewindBackward10, TbRewindForward10 } from 'react-icons/tb'
import PodcastCard from '../components/PodcastCard'
import { BiDislike, BiLike, BiUser } from 'react-icons/bi'
import { PiFlagPennant } from "react-icons/pi";
import { Link, useParams } from 'react-router-dom'
import { deleteComment, getPodcast, getPodcastComments, reactToPodcast, submitComment } from '../services/podcasts'
import { useAuth } from '../providers/AuthProvider'
import { AiOutlineDelete } from 'react-icons/ai'

const PodcastDetail = () => {

  const [clampDescription, setClampDescription] = useState(true)
  const [podcast, setPodcast] = useState()
  const [comments, setComments] = useState({ results: [], count: 0 });
  const [commentText, setCommentText] = useState('');
  const { token, user } = useAuth()

  const { id } = useParams();

  const handleCommentSubmit = async () => {
    if (!commentText.trim()) return;
    try {
      await submitComment(id, user.id, commentText, token);
      setCommentText('');
      const updatedComments = await getPodcastComments(id, 1, token);
      setComments(updatedComments);
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  const handleReaction = async (reactionType) => {
    try {
      const isLiked = reactionType === 'like'; // Convert to boolean
      await reactToPodcast(id, user.id, isLiked, token);
      const updatedPodcast = await getPodcast(id, token);
      setPodcast(updatedPodcast);
    } catch (error) {
      console.error('Error reacting to podcast:', error);
    }
  };


  const handleDeleteComment = async (commentId) => {
    try {
      await deleteComment(commentId, token);
      const updatedComments = await getPodcastComments(id, 1, token);
      setComments(updatedComments);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };
  useEffect(() => {


    const fetchComments = async () => {
      try {
        const commentData = await getPodcastComments(id, 1, token);
        setComments(commentData || []);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };


    fetchComments();
  }, [id, token, user]);


  useEffect(() => {
    const fetchPodcast = async (id, token) => {
      try {
        const response = await getPodcast(id, token);

        setPodcast(response);
      } catch (error) {
        console.error('Error fetching podcast:', error);
      }
    };



    fetchPodcast(id, token);

  }, [id, token]);

  if (!podcast) return <p>Loading...</p>;

  return (
    <div className='my-[32px] flex flex-col gap-6 '>
      <section className='mx-auto max-w-7xl' >
        <div className='grid grid-cols-3 gap-4 '>
          <div className='col-span-full md:col-span-2 '>
            <VideoPlayer src={podcast.firebase_url} />
            <div className='flex flex-col gap-3 px-2 my-8 md:px-0 ' >
              <h4 className='h4'>{podcast.title}</h4>
              <div className='flex items-center gap-6 b3 text-primary-100 '>
                <p>{podcast.views} Views</p>
                <p>{new Date(podcast.created_at).toLocaleDateString()}</p>
              </div>
              <div className='flex gap-8'>
                <button onClick={() => handleReaction('like')} className='flex flex-col items-center justify-center gap-2'>
                  <BiLike size={24} />
                  <span className='h9'>{podcast.total_likes}</span>
                </button>
                <button onClick={() => handleReaction('dislike')} className='flex flex-col items-center justify-center gap-2'>
                  <BiDislike size={24} />
                  <span className='h9'>{podcast.total_dislikes}</span>
                </button>

              </div>
            </div>

            <div className='px-2 mt-11 md:px-0'>
              <h6 className='h7' >About this video</h6>
              <div className='mt-3'>
                <p className={` text-primary-100 b6 ${clampDescription && 'line-clamp-2'} `}>
                  {
                    podcast.description.length > 180 && clampDescription ?
                      podcast.description.slice(0, 180) :
                      podcast.description
                  }
                  {

                    clampDescription ?

                      <span className='ml-2 text-white cursor-pointer' onClick={() => { setClampDescription(false) }} >
                        Read More

                      </span> :
                      <div className='text-white cursor-pointer' onClick={() => { setClampDescription(true) }} >
                        Show less
                      </div>
                  }

                </p>

              </div>
            </div>
            <div className='px-2 mt-11 md:px-0'>

              <div className='flex flex-col w-full gap-4 my-4'>
                <textarea
                  placeholder='Write a comment...'
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  className='w-full h-20 p-2 bg-black border-2 border-white resize-none rounded-xl'
                />
                <button onClick={handleCommentSubmit} className='w-20 p-2 text-black bg-white rounded'>
                  Submit
                </button>
              </div>
              <div className='flex justify-between'>
                <h6 className="h7">Comments ({comments.count})</h6>


              </div>
              <div className="flex flex-col mt-10 gap-9">
                {comments.results.map((review) => (
                  <div key={review.id} className="relative p-4 rounded-lg shadow ">
          
                    {user.id === review.user_id && (
                      <button
                        onClick={() => handleDeleteComment(review.id)}
                        className="absolute text-red-500 transition-all top-3 right-3 hover:text-red-700"
                      >
                        <AiOutlineDelete size={22} />
                      </button>
                    )}

                    <div className="flex items-center gap-2">
                 
                      <div className="w-14 h-14">
                        {review.user_profile_picture ? (
                          <img
                            className="object-cover w-full h-full rounded-full"
                            src={review.user_profile_picture}
                            alt={review.user_full_name}
                          />
                        ) : (
                          <BiUser size={40} className="text-gray-400" />
                        )}
                      </div>

              
                      <div>
                        <p className="font-medium b5">{review.user_full_name}</p>
                        <p className="text-gray-500 b7 dark:text-gray-400">
                          {new Date(review.created_at).toLocaleDateString()}
                        </p>
                      </div>
                    </div>

           
                    <p className="mt-3 text-gray-700 dark:text-gray-300">{review.content}</p>
                  </div>
                ))}
              </div>

            </div>
          </div>
          {/* <div className='flex flex-col gap-3 px-4 md:col-span-1 col-span-full md:px-0'>
                        {podcast.related.map((podcast) => (
                            <PodcastCard layout={'list'} key={podcast.id} name={podcast.name} description={podcast.description} owner={podcast.owner} />
                        ))}
                    </div> */}

        </div>
      </section>
      <section className="w-full px-4 mx-auto max-w-7xl sm:px-0">
        <h3 className="h7">More Videos from this Creator</h3>
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-9 gap-y-12 mt-9 ">
                    {podcast.otherVideos.map((video) => (
                        <PodcastCard
                            key={video.id}
                            name={video.name}
                            description={video.description}
                            owner={video.owner}
                            followers="12.6k"
                            views="13M"
                        />
                    ))}
                </div> */}
      </section>

    </div>
  )
}

export default PodcastDetail



const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;

      const updateTime = () => {
        setCurrentTime(video.currentTime);
        setDuration(video.duration);
      };
      video.addEventListener('timeupdate', updateTime);
      return () => {
        video.removeEventListener('timeupdate', updateTime);
      };
    }
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgress = () => {
    const video = videoRef.current;
    const currentTime = video.currentTime;
    const duration = video.duration;
    setProgress((currentTime / duration) * 100);
  };

  const handleSeek = (e) => {
    const video = videoRef.current;
    const newTime = (e.target.value / 100) * video.duration;
    video.currentTime = newTime;
    setProgress(e.target.value);
  };


  const rewind10Seconds = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10;
    }
  };

  const Forward10Seconds = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10;
    }
  };

  return (
    <section className="relative w-full ">
      <video
        ref={videoRef}
        className="w-full rounded-3xl max-h-[500px]"
        src={src}
        onTimeUpdate={handleProgress}
        controls={false}
      >

      </video>

      <div className="absolute left-0 right-0 px-4 py-2 space-x-4 text-white rounded-lg bottom-2">
        <div className='relative '>
          <div className='w-4 rounded-full h-4 bg-white absolute top-[7px] ' style={{
            left: progress + '%'
          }} />
          <input
            type="range"
            className="w-full h-1 overflow-hidden bg-gray-300 rounded-lg appearance-none"
            value={progress}
            onChange={handleSeek}
            onInput={handleSeek}
            style={{
              background: `linear-gradient(to right, white ${progress}%, gray ${progress}%)`,
            }}
          />
        </div>
        <div className='flex items-center justify-between '>
          <div className='flex items-center gap-6'>
            <p>{formatTime(currentTime)}</p>
            <button
              className=" !max-w-fit rounded-full"
              onClick={togglePlayPause}
            >
              {isPlaying ? '❚❚' : '►'}
            </button>
            <div className="flex justify-between gap-6">
              <button className="">
                <TbRewindBackward10 onClick={rewind10Seconds} size={20} />
              </button>

              <button className="">
                <TbRewindForward10 onClick={Forward10Seconds} size={20} />
              </button>
              <div className='text-xs font-bold'>

              </div>

            </div>
          </div>
          <div className="flex items-center justify-between gap-4 rounded-xl">
            <p>{formatTime(duration)}</p>

            <button className="">
              <MdFullscreen />
            </button>
          </div>
        </div>
      </div>

    </section>
  )
}

