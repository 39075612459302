import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/ui/input";
import Button from "../../components/ui/button";
import { MdLockOutline, MdOutlineMail } from "react-icons/md";
import { CiUser } from "react-icons/ci";
import { useForm } from "react-hook-form";
import { registerUser } from "../../services/auth"
import { toast } from "react-toastify";
import { useAuth } from "../../providers/AuthProvider";

const SignUp = () => {

  const navigate = useNavigate();
  const {setToken}=useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
  
    try {

      const response = await registerUser({
        email: data.email,
        password: data.password,
        role: "general_user",
        first_name: data.first_name,
        last_name: data.last_name
      });
      if (response.tokens?.access) {
        setToken(response.tokens?.access)
        localStorage.setItem("accessToken", response.token.access);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      
      toast.error(error.message);
    }
  };

  return (
    <div className="bg-black p-10 rounded-lg md:min-w-[786px]">
      <h3 className="font-[300] h3">Create Account</h3>
      <p className="b4 text-[#d5d5d5d5]">Fill out the required data to create your account.</p>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5 mt-10">
        <Input
          Icon={CiUser}
          placeholder="First Name"
          error={errors.first_name} // Pass error state for name field
          {...register("first_name", { required: "Name is required" })}
        />
        {errors.first_name && <p className="text-sm text-red-500">{errors.first_name.message}</p>}

        <Input
          Icon={CiUser}
          placeholder="Last Name"
          error={errors.last_name} // Pass error state for name field
          {...register("last_name", { required: "Name is required" })}
        />
        {errors.last_name && <p className="text-sm text-red-500">{errors.last_name.message}</p>}


        <Input
          Icon={MdOutlineMail}
          placeholder="Email"
          error={errors.email}
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address",
            },
          })}
        />
        {errors.email && <p className="text-sm text-red-500">{errors.email.message}</p>}


        <Input
          Icon={MdLockOutline}
          type="password"
          placeholder="Password"
          error={errors.password}
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
          })}
        />
        {errors.password && <p className="text-sm text-red-500">{errors.password.message}</p>}


        <Input
          Icon={MdLockOutline}
          type="password"
          placeholder="Confirm Password"
          error={errors.confirmPassword} // Pass error state for confirmPassword field
          {...register("confirmPassword", {
            required: "Please confirm your password",
            validate: (value) => value === watch("password") || "Passwords don't match",
          })}
        />
        {errors.confirmPassword && <p className="text-sm text-red-500">{errors.confirmPassword.message}</p>}


        <div className="flex flex-col gap-3">
          <Button type="submit">Create Account</Button>
          <div className="flex items-center justify-center gap-2">
            <p className="font-extralight">Already have an account?</p>
            <Link to={"/login"} className="text-[20px]">
              Login
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUp;