import { createContext, useContext, useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUserProfile } from "../services/user.js";

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => localStorage.getItem("isAuthenticated") === "true");
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || null);
  const [token, setToken] = useState(() => localStorage.getItem("accessToken") || null);

  const navigate = useNavigate();

  // ✅ Login function
  const login = useCallback((userData, accessToken) => {
    setIsAuthenticated(true);
    setUser(userData);
    setToken(accessToken);

    localStorage.setItem("isAuthenticated", "true");
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("accessToken", accessToken);
  }, []);

  // ✅ Logout function
  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setUser(null);
    setToken(null);

    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");

    navigate("/login");
  }, [navigate]);

  // ✅ Fetch user profile
  const fetchProfile = useCallback(
    async (accessToken = token) => {
      if (!accessToken) return;
      try {
        const profile = await fetchUserProfile(accessToken);
        setUser(profile);
        localStorage.setItem("user", JSON.stringify(profile));
      } catch (error) {
        console.error("Fetching profile failed:", error);
        logout();
      }
    },
    [token, logout]
  );

  // ✅ Auto-fetch profile if access token exists
  useEffect(() => {
    if (token) {
      fetchProfile(token);
    }
  }, [token, fetchProfile]);

  // ✅ Persist authentication state from localStorage on mount
  useEffect(() => {
    const isAuth = localStorage.getItem("isAuthenticated") === "true";
    const storedUser = localStorage.getItem("user");
    const storedToken = localStorage.getItem("accessToken");

    if (isAuth && storedUser && storedToken) {
      setIsAuthenticated(true);
      setUser(JSON.parse(storedUser));
      setToken(storedToken);
    }
  }, []);

  // ✅ Memoized value for context
  const value = useMemo(
    () => ({ isAuthenticated,setUser, user, token, login, logout, fetchProfile, setToken }),
    [isAuthenticated, user, token, login, logout, fetchProfile,setUser]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};