import React from 'react'
import { MdStar } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { FaHeart } from "react-icons/fa";

const ProductCard = ({  isLiked = false, id, name, imgsrc = '/assets/product.jpg', description,  price, rating = '4.5', comments = '15' }) => {
    return (
        <Link to={'/product/' + id} className='overflow-hidden bg-black rounded-3xl'>
            <div className="relative h-[250px] overflow-hidden flex items-center justify-center">
                <img src={imgsrc} alt={name} className="object-cover w-full h-full" />
            </div>
            <div className='flex flex-col gap-3 p-4'>
                <div className='flex flex-col gap-2'>
                    <div className='flex items-center justify-between'>
                        <h6 className='h7'>{name}</h6>
                        {isLiked && <FaHeart size={34} />}
                    </div>
                    <p className='flex items-center gap-2 b6'>
                        {rating} <span><MdStar className='text-yellow-500' size={24} /> </span>({comments})
                    </p>
                </div>
                <h6 className='h7'>{price}</h6>
                <p className='text-primary-100 c8'>{description}</p>
               
            </div>
        </Link>
    )
}

export default ProductCard
