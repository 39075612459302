import React from 'react'


const ContactUs = () => {
    return (
        <div className='flex flex-col gap-y-[100px]' >
            <Hero />
          


        </div>
    )
}

export default ContactUs




const Hero = () => {
    return (
        <section
            className="flex flex-col min-h-[30vh]  justify-center items-center  bg-portalized bg-no-repeat bg-top bg-[length:700px]  ">
            <div className="flex flex-col items-center justify-center max-w-6xl gap-6 mx-auto">
                <h1 className="font-light text-center h3">
                    Get in Touch with Us
                </h1>
                <div className='text-center' > 
                    <p className="text-center text-primary-150 c5">
                        Let us know how we can help.
                    </p>

                    <p className='lowercase c7'>
                        contact@PORTALIZED.com
                    </p>

                </div>
            </div>
        </section>
    )
}



