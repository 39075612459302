import React, { useEffect, useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import Button from '../../components/ui/button.js';
import { useAuth } from '../../providers/AuthProvider.jsx';
import { fetchOrders } from '../../services/orders.js';

const Orders = () => {
  const [orders, setOrders] = useState({
    completed: [],
    inProgress: [],
    cancelled: [],
  });
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();

  useEffect(() => {
    const getOrders = async () => {
      try {
        setLoading(true);
        const response = await fetchOrders(token);

        const categorizedOrders = {
          completed: response.filter(order => order.status === 'shipped'),
          inProgress: response.filter(order => order.status === 'processing'),
          cancelled: response.filter(order => order.status === 'cancelled'),
        };

        setOrders(categorizedOrders);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getOrders();
  }, [token]);

  return (
    <div className='flex flex-col gap-y-[100px]'>
      <section className='w-full mx-auto mt-10 max-w-7xl'>
        <h5 className='ml-2 h5'>Orders</h5>

        <div className='mt-10'>
          <TabGroup>
            <TabList className='flex gap-10'>
              <Tab className='py-1 px-3 focus:outline-none data-[selected]:border-white text-primary-100 data-[selected]:text-white border-transparent h10 border-b-2'>
                Completed
              </Tab>
              <Tab className='py-1 px-3 focus:outline-none data-[selected]:border-white text-primary-100 data-[selected]:text-white border-transparent h10 border-b-2'>
                On Going
              </Tab>
              <Tab className='py-1 px-3 focus:outline-none data-[selected]:border-white text-primary-100 data-[selected]:text-white border-transparent h10 border-b-2'>
                Cancelled
              </Tab>
            </TabList>
            <TabPanels>
              {loading ? (
                <div className='flex items-center justify-center mt-10 text-white'>Loading...</div>
              ) : (
                [
                  ['completed', 'text-green-500'],
                  ['inProgress', 'text-yellow-500'],
                  ['cancelled', 'text-red-500'],
                ].map(([key, textColor], index) => (
                  <TabPanel key={index}>
                    <div className='flex flex-col gap-6 px-2 mt-5 md:px-0'>
                      {orders[key].map(order => (
                        <div className='flex flex-col justify-between p-3 bg-black md:flex-row rounded-3xl' key={order.id}>
                          <div className='flex flex-col gap-4 overflow-hidden md:flex-row'>
                            <div className='relative overflow-hidden md:w-60 md:h-60 rounded-xl'>
                              <img
                                src={order.items[0].product_image}
                                alt={order.items[0].product_name}
                                className='object-cover w-full h-full'
                              />
                            </div>
                            <div className='flex flex-col gap-3'>
                              <div className='flex flex-col gap-3 md:flex-row md:gap-10 text-primary-100'>
                                <p>Ordered: {new Date(order.created_at).toLocaleDateString()}</p>
                              </div>
                              <p className='h5'>${order.total_price}</p>
                              <p className={textColor}>{order.status === 'shipped' ? 'Received' : order.status}</p>
                            </div>
                          </div>
                          {key === 'completed' && (
                            <div className='flex flex-col justify-center gap-2 md:items-center'>
                              <Button className='!text-[17px] md:!max-w-fit'>Write Review</Button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </TabPanel>
                ))
              )}
            </TabPanels>
          </TabGroup>
        </div>
      </section>
    </div>
  );
};

export default Orders;