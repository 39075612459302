import { useParams } from "react-router-dom";

const OrderConfirmation = () => {
  const { orderId } = useParams();

  return (
    <div className="h-[30svh] flex flex-col justify-center text-center">
      <h2 className="text-xl font-bold">Thank You!</h2>
      <p>Your order #{orderId} has been placed successfully.</p>
    </div>
  );
};

export default OrderConfirmation;