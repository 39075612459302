import React, { useState } from 'react'
import { Elements } from "@stripe/react-stripe-js";
import { MdDelete } from 'react-icons/md';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, removeFromCart, updateQuantity } from '../store/slices/cartSlice';
import Button from '../components/ui/button';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Input from '../components/ui/input';
import { useAuth } from '../providers/AuthProvider';
import { placeOrder } from '../services/orders';
import { createPaymentIntent } from '../services/payments';


const stripePromise = loadStripe("pk_test_51R2TjgGph57fWcdRIeohRS4FSDxYzQdpmxqfnuMlHi0x6OTrkh7Ozq77hYlgo3yCQ8QC1z5aOat2u7XcS0N5YlHM00mvrJW0ai");

const Checkout = () => {

  const [orderId, setOrderId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [checkoutStep, setCheckoutStep] = useState("cart");

  const cartItems = useSelector((state) => state.cart.cartItems);


  const handleCheckout = () => {
    setCheckoutStep("shipping");
  };



  const handleShippingSubmit = async (shippingData, totalPrice, token) => {
    try {
      const data = await placeOrder(shippingData, totalPrice, token);
      setOrderId(data.order_id); // Store order ID
      setCheckoutStep("billing");
      initiatePayment(data.order_id, token);
    } catch (error) {
    
      // Check if the error response contains a message
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("Failed to place order. Please try again.");
      }
    }
  };

  const initiatePayment = async (orderId, token) => {
    try {
      const data = await createPaymentIntent(orderId, token)

      setClientSecret(data.client_secret);
    } catch (error) {
      console.error("Payment Intent Error:", error.message);
    }
  };




  return (
    <div className='flex flex-col gap-y-[100px]' >
      <Elements stripe={stripePromise}>
        <section className='w-full py-20 mx-auto max-w-7xl' >
          <div className='grid grid-cols-3 gap-10 px-2 md:px-0'>
            <div className='p-4 rounded-md bg-primary-200 col-span-full md:col-span-2 '>

              {checkoutStep === "cart" && (
                <Cart />
              )}
              {checkoutStep === "shipping" && (
                <ShippingForm handleShipping={handleShippingSubmit} />
              )}
              {checkoutStep === "billing" && (
                <PaymentForm orderId={orderId} clientSecret={clientSecret} />
              )}

            </div>
            <div className="col-span-full md:col-span-1">
              <div className='flex flex-col gap-4 p-4 rounded-md bg-primary-200'>
                <h3 className="h6">Summary</h3>

                <div className="my-4 border-t border-gray-300"></div>

                <div className="flex items-center justify-between">
                  <p className="h8">Total</p>
                  <p className="h8">
                    {cartItems.reduce((acc, item) => acc + item.price_at_purchase * item.quantity, 0).toFixed(2)}
                  </p>
                </div>


                <div className="flex items-center justify-between">
                  <p className="h8">Tax (0%)</p>
                  <p className="h8">0.00</p>
                </div>


                <div className="flex items-center justify-between">
                  <p className="font-light h8 ">Shipping</p>
                  <p className="h8">5.00</p>
                </div>





                <div className="flex items-center justify-between mt-4 font-semibold">
                  <p className='h6'>Total</p>
                  <p className='h6'>
                    {(cartItems.reduce((acc, item) => acc + item.price_at_purchase * item.quantity, 0) + 5.00).toFixed(2)}
                  </p>
                </div>

                <div className="my-4 border-t border-gray-300"></div>

                {checkoutStep === "cart" && (
                  <Button disabled={cartItems.length === 0} onClick={handleCheckout} >
                    Continue to Checkout
                  </Button>
                )}


              </div>
            </div>
          </div>
        </section>

      </Elements>
    </div>
  )
}

export default Checkout




function Cart() {

  const cartItems = useSelector((state) => state.cart.cartItems);
  const dispatch = useDispatch();
  const { token } = useAuth()




  const handleRemoveItem = (id) => {
    dispatch(removeFromCart({ itemId: id, token }));
  };

  const handleUpdateQuantity = async (id, newQuantity) => {
    if (newQuantity <= 0) {
      dispatch(removeFromCart({ itemId: id, token }));

    } else {
      dispatch(updateQuantity({ itemId: id, quantity: newQuantity, token }));
    }
  };

  return (

    <div className="w-full">
      <div className="flex items-center justify-between">
        <h6 className="font-light h6">{`Your Cart (${cartItems.length})`}</h6>
      </div>
      <div className="mt-4 space-y-4">
        {cartItems.map((item) => (
          <div
            key={item.id}
            className="flex md:flex-row flex-col   justify-between  md:items-center  gap-4 bg-[#121212] p-3 rounded-lg"
          >
            <div className='flex gap-6 '>
              <img
                src={item.product_image}
                alt={item.product_name}
                className="object-cover w-32 h-32 rounded "
              />

              <div className="flex flex-col justify-between flex-1">
                <p className="h8">{item.product_name}</p>
                <p className="mt-1 h7">${item.price_at_purchase}</p>
                <button
                  onClick={() => handleRemoveItem(item.id)}
                  className="flex items-center gap-1 "
                >
                  <MdDelete /> Delete
                </button>
              </div>
            </div>

            <div className="flex items-center gap-4 bg-black max-w-fit rounded-xl">
              <button
                onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)}
                className="px-2 py-2 rounded max-w-fit"
              >
                -
              </button>
              <span className="">{item.quantity}</span>
              <button
                onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}
                className="px-2 py-2 rounded max-w-fit"
              >
                +
              </button>
            </div>
          </div>
        ))}
      </div>

    </div>
  )
}



const ShippingForm = ({ handleShipping }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { token } = useAuth()
  const cartItems = useSelector((state) => state.cart.cartItems);

  const onSubmit = (data) => {
    const totalPrice = cartItems.reduce((acc, item) => acc + item.price_at_purchase * item.quantity, 0).toFixed(2)

    handleShipping(data, totalPrice, token);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-left h2">Shipping Details</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6 mt-9">

        <div>
          <label className='ml-1 h9' htmlFor="email">Email street_address</label>
          <Input
            error={errors.email}
            type="email"
            id="email"
            placeholder="Enter your email"
            className=" !bg-primary-300 "
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email street_address',
              },
            })}
          />
          {errors.email && <p className="text-red-500">{errors.email.message}</p>}
        </div>


        <div className="flex items-center justify-start gap-2 h9 ">
          <input type="checkbox" id="newsletter" {...register('newsletter')} className='w-5 h-5 rounded text-primary-300 accent-primary-200 focus:ring-2 focus:ring-black/40 bg-primary-300 ' />
          Email me with new offers and newsletter
        </div>

        <h6 className=" h8">Shipping street_address</h6>
        <div className="grid gap-6 md:grid-cols-2">
          <div className='flex flex-col gap-2'>
            <label className='ml-1 h9' htmlFor="first_name">First Name</label>
            <Input
              error={errors.first_name}
              type="text"
              id="first_name"
              placeholder="Enter your first name"
              className=" !bg-primary-300 "
              {...register('first_name', { required: 'First name is required' })}
            />
            {errors.first_name && <p className="text-red-500">{errors.first_name.message}</p>}
          </div>

          <div className='flex flex-col gap-2'>
            <label className='ml-1 h9' htmlFor="last_name">Last Name</label>
            <Input
              error={errors.last_name}

              type="text"
              id="last_name"
              placeholder="Enter your last name"
              className=" !bg-primary-300 "
              {...register('last_name', { required: 'Last name is required' })}
            />
            {errors.last_name && <p className="text-red-500">{errors.last_name.message}</p>}
          </div>

          <div className='flex flex-col gap-2'>
            <label className='ml-1 h9' htmlFor="country">Country</label>
            <Input
              error={errors.country}

              type="text"
              id="country"
              placeholder="Enter your country"
              className=" !bg-primary-300 "
              {...register('country', { required: 'Country is required' })}
            />
            {errors.country && <p className="text-red-500">{errors.country.message}</p>}
          </div>
          <div className='flex flex-col gap-2'>
            <label className='ml-1 h9' htmlFor="state">State</label>
            <Input
              error={errors.state}

              type="text"
              id="state"
              placeholder="Enter your country"
              className=" !bg-primary-300 "
              {...register('state', { required: 'Country is required' })}
            />
            {errors.state && <p className="text-red-500">{errors.state.message}</p>}
          </div>
          <div className='flex flex-col gap-2'>
            <label className='ml-1 h9' htmlFor="city">City</label>
            <Input
              error={errors.city}

              type="text"
              id="city"
              placeholder="Enter your city"
              className=" !bg-primary-300 "
              {...register('city', { required: 'City is required' })}
            />
            {errors.city && <p className="text-red-500">{errors.city.message}</p>}
          </div>
          <div className='flex flex-col gap-2'>
            <label className='ml-1 h9' htmlFor="street_address">Address</label>
            <Input
              error={errors.street_address}

              type="text"
              id="street_address"
              placeholder="Enter your street_address"
              className=" !bg-primary-300 "
              {...register('street_address', { required: 'address is required' })}
            />
            {errors.street_address && <p className="text-red-500">{errors.street_address.message}</p>}
          </div>
          <div className='flex flex-col gap-2'>
            <label className='ml-1 h9' htmlFor="zip_code">Zip</label>
            <Input
              error={errors.zip_code}

              type="text"
              id="zip_code"
              placeholder="Enter your zip"
              className=" !bg-primary-300 "
              {...register('zip_code', { required: 'zip is required' })}
            />
            {errors.zip_code && <p className="text-red-500">{errors.zip_code.message}</p>}
          </div>
          <div className='flex flex-col gap-2'>
            <label className='ml-1 h9' htmlFor="Phone Number">Phone Number</label>
            <Input
              error={errors.phone_number}
              type="text"
              id="phone_number"
              placeholder="Enter your Phone Number"
              className=" !bg-primary-300 "
              {...register('phone_number', { required: 'Phone Number is required' })}
            />
            {errors.phone_number && <p className="text-red-500">{errors.phone_number.message}</p>}
          </div>
        </div>




        <button

          type="submit"
          className="w-full px-8 py-2 mx-auto mt-6 text-black bg-white rounded-lg max-w-fit "
        >
          Confirm
        </button>
      </form>
    </div>
  )
}



const PaymentForm = ({ orderId, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handlePayment = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setLoading(true);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: elements.getElement(CardElement) }
    });

    if (result.error) {
      console.error("Payment Failed:", result.error.message);
      toast.error(result.error.message);
    } else if (result.paymentIntent.status === "succeeded") {
      toast.success("Payment successful!");

      // ✅ Clear cart after payment is confirmed
      dispatch(clearCart());

      // ✅ Redirect to confirmation page
      navigate(`/order-confirmation/${orderId}`);
    }
    setLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-left h2">Payment Details</h2>
      <form className='flex flex-col gap-6 mt-10 ' >

        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#333",
                backgroundColor: "#f7f7f7",
                fontFamily: "Arial, sans-serif",
                "::placeholder": {
                  color: "#a3a3a3",
                },
                padding: "12px",
                borderRadius: "8px",
              },
              invalid: {
                color: "#ff4d4d",
              },
            },
          }}
          className="w-full p-3 bg-white border rounded-lg shadow-md"
        />
        <div className="flex items-center justify-center col-span-2 mt-4">
          <button
            onClick={handlePayment} disabled={!stripe || loading}

            className="w-full px-8 py-2 mx-auto mt-6 text-black bg-white rounded-lg max-w-fit "
          >
            {loading ? "Processing..." : "Pay Now"}
          </button>

        </div>
      </form>
    </div>
  )
}