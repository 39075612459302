import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider"; // ✅ Import AuthProvider

const AuthLayout = () => {
  const { token } = useAuth(); // ✅ Get token from AuthProvider
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/"); // ✅ Redirect to home if already logged in
    }
  }, [token, navigate]); // ✅ Runs when token changes

  return (
    <main className="flex flex-col items-center justify-center h-screen">
      <Outlet />
    </main>
  );
};

export default AuthLayout;