import { getRequest,putRequest,postRequest } from "./index";

export const fetchUserProfile = async (token) => {
  return getRequest("users/profile", token);
};


export const editUserProfile = async (token, data) => {
  return putRequest("users/profile/edit", data, token);
};


export const updateUserPassword = async (token, data) => {
  return postRequest("users/profile/update-password", data, token);
};