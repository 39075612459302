import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addToCartAPI, updateCartItemAPI, clearCartAPI,getUserCartAPI,removeFromCartAPI } from "../../services/cart";




// ✅ Fetch Cart from Backend
export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (token, { rejectWithValue }) => {
    try {
      const response = await getUserCartAPI(token);
      return response.items || []; // ✅ Return cart items array
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


// ✅ Async Thunks for API Actions
export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async ({ productId, quantity, token }, { rejectWithValue }) => {
    try {
      const response = await addToCartAPI(productId, quantity, token);
      console.log("here",response.items);
      
         return response.items;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateQuantity = createAsyncThunk(
  "cart/updateQuantity",
  async ({ itemId, quantity, token }, { rejectWithValue }) => {
    console.log(itemId,quantity,token);
    
    try {
      const response = await updateCartItemAPI(itemId, quantity, token);
      return response.items;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async ({ itemId, token }, { rejectWithValue }) => {
    try {
      await removeFromCartAPI(itemId, token);
      return { itemId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const clearCart = createAsyncThunk(
  "cart/clearCart",
  async (token, { rejectWithValue }) => {
    try {
      await clearCartAPI(token);
      return {};
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// ✅ Cart Slice
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    totalQuantity: 0,
    status: "idle",
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
     .addCase(fetchCart.fulfilled, (state, action) => {
        state.cartItems = action.payload;
        state.totalQuantity = action.payload.reduce((acc, item) => acc + item.quantity, 0);
      })
      .addCase(addToCart.fulfilled, (state, action) => {
           state.cartItems = action.payload;  // ✅ Replace cart with updated items
            state.totalQuantity = state.cartItems.reduce((acc, item) => acc + item.quantity, 0);
      })
      .addCase(updateQuantity.fulfilled, (state, action) => {
        console.log(action);
        
         state.cartItems = action.payload;  // ✅ Replace cart with updated items
            state.totalQuantity = state.cartItems.reduce((acc, item) => acc + item.quantity, 0);
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        const { itemId } = action.payload;
        state.cartItems = state.cartItems.filter((item) => item.id !== itemId);
        state.totalQuantity = state.cartItems.reduce((acc, item) => acc + item.quantity, 0);
      })
      .addCase(clearCart.fulfilled, (state) => {
        state.cartItems = [];
        state.totalQuantity = 0;
      });
  },
});

export default cartSlice.reducer;