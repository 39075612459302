import { getRequest ,postRequest,patchRequest, deleteRequest} from "./index";

export const fetchProducts = async (search, page = 1) => {
  const queryParams = new URLSearchParams();
  if (search) queryParams.append("search", search);
   queryParams.append("page", page); 

  return getRequest(`products/list/?${queryParams.toString()}`);
};


export const addProduct = async (productData, token) => {
  return postRequest("products/add", productData, token);
};



export const checkProductExists = async (name) => {
   return getRequest(`products/check-name/?name=${name}`);
};


export const updateProduct = async (id, productData, token) => {
  return patchRequest(`products/${id}/update`, productData, token);
};


export const deleteProduct = async (id, token) => {
  return deleteRequest(`products/${id}/delete`, token);
};



export const getProduct = async (id, token) => {
  return getRequest(`products/${id}/`, token);
};