import { getRequest ,postRequest, deleteRequest, putRequest} from "./index";

export const fetchPodcasts = async (search, page = 1) => {
  const queryParams = new URLSearchParams();
  if (search) queryParams.append("search", search);
   queryParams.append("page", page); 

  return getRequest(`podcasts/?${queryParams.toString()}`);
};



export const addPodcast = async (productData, token) => {
  return postRequest("podcasts/upload", productData, token);
};



export const updatePodcast = async (id, productData, token) => {
  return putRequest(`podcasts/${id}/update`, productData, token);
};




export const deletePodcast = async (id, token) => {
  return deleteRequest(`podcasts/${id}/delete`, token);
};




export const getPodcast = async (id, token) => {
  return getRequest(`podcasts/${id}/`, token);
};


export const getPodcastComments = async (video_id, page = 1,token) => {
  return getRequest(`podcasts/${video_id}/comments/?page=${page}`,token);
};



export const submitComment = async (podcastId, userId, commentText, token) => {
  return postRequest(`podcasts/${podcastId}/comment`, { userId, content:commentText }, token);
};

export const reactToPodcast = async (podcastId, userId, isLiked, token) => {
  return postRequest(`podcasts/${podcastId}/like`, { userId, is_liked: isLiked }, token);
};


export const deleteComment=async(comment_id,token)=>{
  return deleteRequest(`/podcasts/comments/${comment_id}/delete`,token)
}