import { getRequest, postRequest, deleteRequest, putRequest } from "./index";

// ✅ Add Product to Cart (or update quantity if exists)
export const addToCartAPI = async (productId, quantity, token) => {
  return postRequest(
    "cart/add",
    { product_id: productId, quantity },
    token
  );
};

// ✅ Update Cart Item Quantity
export const updateCartItemAPI = async (itemId, quantity, token) => {
  return putRequest(
    `cart/update/${itemId}`,
    { quantity },
    token
  );
};

// ✅ Remove Item from Cart
export const removeFromCartAPI = async (itemId, token) => {
  return deleteRequest(`cart/remove/${itemId}`, token);
};

// ✅ Clear Entire Cart
export const clearCartAPI = async (token) => {
  return deleteRequest("cart/delete/", token);
};



// ✅ Fetch User Cart
export const getUserCartAPI = async (token) => {
  return getRequest("cart", token);
};

