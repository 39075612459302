import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PrivateRoute = () => {
  const { user, loading } = useAuth();

  if (loading) return <p>Loading...</p>; // Prevent redirect before checking auth

  return user ?<>
       <Header />
       <Outlet />
       <Footer/>
  </>  : <Navigate to="/login" replace />;
};

export default PrivateRoute;