import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { IoMdVolumeHigh } from "react-icons/io";
import { FaRegClosedCaptioning } from "react-icons/fa";
import { MdArrowForward, MdFullscreen, MdOutline1xMobiledata } from "react-icons/md";
import { MdOutlineSettings } from "react-icons/md";
import { TbRewindBackward10, TbRewindForward10 } from "react-icons/tb";
import PodcastCard from '../components/PodcastCard';
import { Merchandise as MerchandiseShop } from './Shop'

const Landing = () => {
  return (
    <div className='flex flex-col gap-y-[100px]' >
      <Hero />
      <VideoPlayer />
      <Cta />
      <MerchandiseShop />
      {/* <Trending /> */}
      {/* <Vision/> */}
      {/* <Faqs/> */}
    </div>
  )
}

export default Landing

const Hero = () => {
  return (
    <section
      className="flex flex-col min-h-[60vh]  justify-center items-center  bg-portalized bg-no-repeat bg-top bg-[length:700px]  ">
      <div className="flex flex-col items-center justify-center max-w-6xl gap-6 mx-auto">
        <h1 className="font-light text-center top-heading">
          Your Journey, Your Platform, Your Future
        </h1>

        <Link to={'/login'} className="btn-link !max-w-fit !rounded-full !px-10">
          Let’s Get Started
        </Link>
      </div>
      <img src="/assets/portalized.svg" alt="Example SVG" className="mt-10 h-[100px] md:px-0 px-4 " />
    </section>
  )
}
const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;

      const updateTime = () => {
        setCurrentTime(video.currentTime);
        setDuration(video.duration);
      };
      video.addEventListener('timeupdate', updateTime);
      return () => {
        video.removeEventListener('timeupdate', updateTime);
      };
    }
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgress = () => {
    const video = videoRef.current;
    const currentTime = video.currentTime;
    const duration = video.duration;
    setProgress((currentTime / duration) * 100);
  };

  const handleSeek = (e) => {
    const video = videoRef.current;
    const newTime = (e.target.value / 100) * video.duration;
    video.currentTime = newTime;
    setProgress(e.target.value);
  };


  const rewind10Seconds = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10;
    }
  };

  const Forward10Seconds = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10;
    }
  };

  return (
    <section className="relative w-full bg-primary-300 mx-auto py-[50px]">
      <div className='relative w-full mx-auto max-w-7xl '>
        <video
          ref={videoRef}
          className="w-full rounded-lg"
          src="/assets/port_2.mp4"
          onTimeUpdate={handleProgress}
          controls={false}
        >

        </video>

        <div className="absolute left-0 right-0 px-4 py-2 space-x-4 text-white rounded-lg bottom-2">
          <div className='relative '>
            <div className='w-1 rounded-full h-4 bg-white absolute top-[7px] ' style={{
              left: progress + '%'
            }} />
            <input
              type="range"
              className="w-full h-1 overflow-hidden bg-gray-300 rounded-lg appearance-none"
              value={progress}
              onChange={handleSeek}
              onInput={handleSeek}
              style={{
                background: `linear-gradient(to right, white ${progress}%, gray ${progress}%)`,
              }}
            />
          </div>
          <div className='flex items-center justify-between '>
            <div className='flex items-center gap-6'>
              <button
                className=" text-white sm:text-black sm:py-2 sm:px-4 rounded-xl focus:ring-2 ring-white/30   !max-w-fit sm:bg-white   "
                onClick={togglePlayPause}
              >
                {isPlaying ? '❚❚' : '►'}
              </button>
              <div className="flex items-center justify-between gap-2 sm:gap-4 sm:py-4 sm:px-6 bg-primary-300/30 sm:bg-primary-300 rounded-xl">
                <button className="">
                  <TbRewindBackward10 onClick={rewind10Seconds} size={20} />
                </button>

                <button className="">
                  <TbRewindForward10 onClick={Forward10Seconds} size={20} />
                </button>
                <div className='text-xs font-bold'>
                  <p>{`${formatTime(currentTime)} / ${formatTime(duration)}`}</p>
                </div>

              </div>
            </div>
            <div className="flex items-center justify-between gap-2 sm:gap-4 sm:py-4 sm:px-6 bg-primary-300/30 sm:bg-primary-300 rounded-xl">
              <button className="">
                <IoMdVolumeHigh />
              </button>
              <button className="">
                <FaRegClosedCaptioning />
              </button>
              <button className="">
                <MdOutline1xMobiledata />
              </button>
              <button className="">
                <MdOutlineSettings />
              </button>
              <button className="">
                <MdFullscreen />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const Cta = () => {
  return (
    <section className=' md:h-[60vh] max-w-8xl mx-auto px-6 md:px-[80px] ' >
      <div className='grid w-full h-full grid-cols-1 md:grid-cols-2 '>
        <div className='flex flex-col justify-center h-full gap-4 ' >
          <h1 className='h1'>Portalized is Transforming the Landscape, Connecting Athletes and Coaches for Unmatched Success!</h1>
          {/* <p className='b6'>Whether you're part of a college or not, there is an opportunity waiting for you through Portalized.</p> */}
          <Link to={'/sign-up'} className="btn-link !max-w-fit !rounded-full !px-10">
            Let's Get Started
          </Link>

        </div>
        <div className='h-full bg-no-repeat bg-cover bg-portalized' />
      </div>

    </section>

  )

}

const Trending = () => {
  const podcasts = [
    { id: 1, name: 'Do’s & Don’ts of Basketball', description: 'Chartreuse hexagon aesthetic jianbing offal craft artisan. Man goth truffaut tousled cardigan. Bodega subway.', owner: 'Khaleesi North' },
    { id: 2, name: 'Winning Strategies in Soccer', description: 'Vaporware marfa direct chicharrones put quinoa man.', owner: 'Arya Stark' },
    { id: 3, name: 'Training Like a Pro', description: 'Swag ethical book same food cray four plaid fit.', owner: 'Jon Snow' },
    { id: 4, name: 'Secrets of the Gym', description: 'Man goth truffaut tousled cardigan. Bodega subway.', owner: 'Daenerys Targaryen' },
    { id: 5, name: 'Mastering Tennis Techniques', description: 'Offal craft artisan. Man goth truffaut tousled cardigan.', owner: 'Tyrion Lannister' },
    { id: 6, name: 'Mental Strength in Sports', description: 'Chartreuse hexagon aesthetic jianbing offal craft artisan.', owner: 'Sansa Stark' },
  ];

  return (
    <section className='py-12 bg-primary-300'>
      <div className='flex flex-col items-center justify-center mx-auto max-w-8xl'>
        <h1 className='p-2 text-center h1'>Portal News</h1>
        <p className='p-2 text-center c3 text-primary-100'>Listen to engaging discussions and insights from top athletes, coaches, and experts worldwide.</p>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-[60px] sm:px-0 p-3'>
          {podcasts.map((podcast) => (
            <PodcastCard key={podcast.id} name={podcast.name} description={podcast.description} owner={podcast.owner} />
          ))}
        </div>
        <button className='flex items-center gap-4 px-8 py-4 mt-10 text-white bg-black border h7 rounded-xl max-w-fit'>
          Explore All <span><MdArrowForward /></span>
        </button>
      </div>
    </section>
  );
}
const Vision = () => {

  return (
    <>
      {/* VISIOSN */}
      <section className='p-3 bg-primary-300 sm:p-0'>
        <div className='py-12 mx-auto max-w-8xl '>
          <div className='flex flex-col items-center justify-center'>
            <h4 className='px-8 py-4 text-center text-black bg-white rounded-full h8 max-w-fit'>OUR VISION</h4>
            <h5 className='h5 text-center mt-4 mb-[50px] text-primary-100'>
              To Empower student athletes to claim autonomy over their careers by breaking down systems and institutions that inhibit their right to capitalize on their abilities and make their own decisions.
            </h5>
          </div>

          <div className="grid grid-cols-1 gap-8 md:grid-cols-7">
            <div className="flex flex-col items-center justify-center col-span-1 gap-6 p-8 bg-black rounded-2xl md:col-span-2">
              <img src="/assets/clients.svg" alt="Coaches" />
              <h6 className="h8">Coaches</h6>
              <p className="b6 text-primary-100">Coaches reach out to Athletes</p>
            </div>

            <div className="flex flex-col col-span-1 gap-8 md:col-span-3">
              <div className="flex items-center justify-center w-full h-full gap-6 p-8 bg-black rounded-2xl">
                <img src="/assets/goals.svg" alt="Goals" />
                <div className="flex flex-col gap-6">
                  <h6 className="h8">Goals</h6>
                  <p className="b6 text-primary-100">Together our platform helps you reach your goals.</p>
                </div>
              </div>

              <div className="flex items-center justify-center w-full h-full gap-6 p-8 bg-black rounded-2xl">
                <img src="/assets/athlete.svg" alt="Athletes" />
                <div className="flex flex-col gap-6">
                  <h6 className="h8">Athletes</h6>
                  <p className="b6 text-primary-100">Our platform provides a space for athletes to grow.</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center col-span-1 gap-6 p-8 bg-black rounded-2xl md:col-span-2">
              <img src="/assets/achivements.svg" alt="Achievements" />
              <h6 className="h8">Achievements</h6>
              <p className="text-center b6 text-primary-100">Collaboration between coaches and athletes.</p>
            </div>
          </div>

        </div>
      </section>
      {/* ABOUT */}
      <section className='bg-primary-300'>
        <div className='p-2 sm:p-[80px] max-w-8xl  mx-auto'>
          <h2 className='text-center h2 '>
            We are a nationwide sports movement, poised to revolutionize the landscape of college sports as a whole. At our core, we are driven by a passion for empowering Student Athletes to claim autonomy over their careers by breaking down systems and institutions that inhibit their right to capitalize on their abilities and make their own decisions and reshaping the way they navigate their collegiate careers.
            POWER TO THE PLAYERS!
          </h2>
        </div>


      </section>

    </>



  )


}




