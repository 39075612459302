import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Input from '../components/ui/input'
import Button from '../components/ui/button'
import { IoSearch } from 'react-icons/io5'
import ProductCard from '../components/ProductCard'
import { fetchProducts } from '../services/products'

const Shop = () => {
  return (
    <div className='flex flex-col gap-y-[100px]' >
      <Hero />
      <Merchandise />

    </div>
  )
}

export default Shop




const Hero = () => {
  return (
    <section
      className="flex flex-col min-h-[50vh]  justify-center items-center  bg-portalized bg-no-repeat bg-top bg-[length:700px] sm:px-0 px-2  ">
      <div className="flex flex-col items-center justify-center max-w-6xl gap-6 mx-auto">
        <h1 className="font-light text-center h3">
          Shop Our Exclusive Portalized Merchandise
        </h1>
      
        {/* <div className='w-full max-w-3xl'>
          <Input Icon={IoSearch} placeholder='Search for your favorite athlete, coach or Topic' className='w-full bg-primary-200' />
        </div> */}
      </div>
    </section>
  )
}


export const Merchandise = () => {
   const [products, setProducts] = useState([]); // Store products
  const [loading, setLoading] = useState(true); // Show loading state
  const [error, setError] = useState(null); // Handle errors

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await fetchProducts();
        setProducts(response.results); // Assuming pagination response structure
      } catch (err) {
        setError("Failed to fetch products.");
      } finally {
        setLoading(false);
      }
    };

    getProducts();
  }, []);

  if (loading) return <p className="text-center">Loading products...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <section className="w-full px-4 mx-auto max-w-7xl sm:px-0">
   
          <div  className="mb-12">
            <h3 className="h5">Trending Merchandise</h3>
            {products.length > 0 ? ( 
               <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-9 gap-y-12 mt-9">
              {products.map((product) => (
                <ProductCard
                  key={product.id}
                  id={product.id}
                  name={product.name}
                  price={product.price}
                  description={product.description}
                  rating={product.average_rating}
                  comments={product.total_reviews}
                  imgsrc={product.images[0]}
                />
              ))}
            </div>):
         <p className="text-center text-gray-500">No Merchandise available.</p>
            }
          </div>
    
    </section>
  );
};