import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import Input from "../components/ui/input";
import PodcastCard from "../components/PodcastCard";
import { fetchPodcasts } from "../services/podcasts"

const Listen = () => {
  return (
    <div className="flex flex-col gap-y-[100px]">
      <Hero />
      <Podcasts />
    </div>
  );
};

export default Listen;

const Hero = () => {
  return (
    <section className="flex flex-col min-h-[50vh] justify-center items-center bg-portalized bg-no-repeat bg-top bg-[length:700px] sm:px-0 px-2">
      <div className="flex flex-col items-center justify-center max-w-6xl gap-6 mx-auto">
        <h1 className="font-light text-center h1">
          Listen to our conversation with Athletes,
          <br />
          Coaches and Parents
        </h1>
        {/* <div className="w-full max-w-3xl">
          <Input
            Icon={IoSearch}
            placeholder="Search for your favorite athlete, coach, or topic"
            className="w-full bg-primary-200"
          />
        </div> */}
      </div>
    </section>
  );
};

const Podcasts = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPodcasts = async () => {
      try {
        const data = await fetchPodcasts(); // Fetch podcast data from API
        setPodcasts(data);
      } catch (err) {
        setError("Failed to fetch podcasts.");
      } finally {
        setLoading(false);
      }
    };

    getPodcasts();
  }, []);

  if (loading) return <div className="py-4 text-center">Loading podcasts...</div>;
  if (error) return <div className="py-4 text-center text-red-500">{error}</div>;

  return (
    <section className="w-full px-4 mx-auto max-w-7xl sm:px-0">
      <h3 className="h5">Trending Topics</h3>
      {podcasts.results.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-9 gap-y-12 mt-9">
          {podcasts.results.map((podcast) => (
            <PodcastCard
            id={podcast.id}
              key={podcast.id}
              name={podcast.title}
              description={podcast.description}
              followers={`${podcast.followers || "N/A"}`}
              views={`${podcast.views || "0"}`}
              cover_image={podcast.cover_image}
            />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">No podcasts available.</p>
      )}
    </section>
  );
};