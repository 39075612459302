import React, { useState,useRef,useEffect } from 'react'
import { TfiReload } from "react-icons/tfi";
import { IoMdVolumeHigh } from "react-icons/io";
import { FaRegClosedCaptioning } from "react-icons/fa";
import { MdFullscreen, MdOutline1xMobiledata } from "react-icons/md";
import { MdOutlineSettings } from "react-icons/md";
import { TbRewindBackward10, TbRewindForward10 } from "react-icons/tb";

const Watch = () => {
    return (
        <div className='flex flex-col gap-y-[100px]' >
            <Hero />
            <VideoPlayer />
           
        </div>
    )
}

export default Watch




const Hero = () => {
    return (
        <section
            className="flex flex-col min-h-[50vh]  justify-center items-center  bg-portalized bg-no-repeat bg-top bg-[length:700px] p-2  md:p-0  ">
            <div className="flex flex-col items-center justify-center max-w-6xl gap-6 mx-auto">
                <h1 className="font-light text-center top-heading">
                 Coming soon on YouTube<br/>
                </h1>
            
            </div>
        </section>
    )
}


const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;

      const updateTime = () => {
        setCurrentTime(video.currentTime);
        setDuration(video.duration);
      };
      video.addEventListener('timeupdate', updateTime);
      return () => {
        video.removeEventListener('timeupdate', updateTime);
      };
    }
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgress = () => {
    const video = videoRef.current;
    const currentTime = video.currentTime;
    const duration = video.duration;
    setProgress((currentTime / duration) * 100);
  };

  const handleSeek = (e) => {
    const video = videoRef.current;
    const newTime = (e.target.value / 100) * video.duration;
    video.currentTime = newTime;
    setProgress(e.target.value);
  };


  const rewind10Seconds = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10;
    }
  };

  const Forward10Seconds = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10;
    }
  };

  return (
    <section className="relative w-full bg-primary-300 mx-auto py-[50px]">
      <div className='relative w-full mx-auto max-w-7xl '>
        <video
          ref={videoRef}
          className="w-full rounded-lg"
          src="/assets/port.mp4"
          onTimeUpdate={handleProgress}
          controls={false}
        >

        </video>

        <div className="absolute left-0 right-0 px-4 py-2 space-x-4 text-white rounded-lg bottom-2">
          <div className='relative '>
            <div className='w-1 rounded-full h-4 bg-white absolute top-[7px] ' style={{
              left: progress + '%'
            }} />
            <input
              type="range"
              className="w-full h-1 overflow-hidden bg-gray-300 rounded-lg appearance-none"
              value={progress}
              onChange={handleSeek}
              onInput={handleSeek}
              style={{
                background: `linear-gradient(to right, white ${progress}%, gray ${progress}%)`,
              }}
            />
          </div>
          <div className='flex items-center justify-between '>
            <div className='flex items-center gap-6'>
              <button
                className=" text-white sm:text-black sm:py-2 sm:px-4 rounded-xl focus:ring-2 ring-white/30   !max-w-fit sm:bg-white   "
                onClick={togglePlayPause}
              >
                {isPlaying ? '❚❚' : '►'}
              </button>
              <div className="flex items-center justify-between gap-2 sm:gap-4 sm:py-4 sm:px-6 bg-primary-300/30 sm:bg-primary-300 rounded-xl">
                <button className="">
                  <TbRewindBackward10 onClick={rewind10Seconds} size={20} />
                </button>

                <button className="">
                  <TbRewindForward10 onClick={Forward10Seconds} size={20} />
                </button>
                <div className='text-xs font-bold'>
                  <p>{`${formatTime(currentTime)} / ${formatTime(duration)}`}</p>
                </div>

              </div>
            </div>
            <div className="flex items-center justify-between gap-2 sm:gap-4 sm:py-4 sm:px-6 bg-primary-300/30 sm:bg-primary-300 rounded-xl">
              <button className="">
                <IoMdVolumeHigh />
              </button>
              <button className="">
                <FaRegClosedCaptioning />
              </button>
              <button className="">
                <MdOutline1xMobiledata />
              </button>
              <button className="">
                <MdOutlineSettings />
              </button>
              <button className="">
                <MdFullscreen />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const TrendingTopics = () => {

    const trendingtopics = [
        {
            title: "What is PORTALIZED? How it can help athletes achieve their goals.",
            time: "2 months ago",
            views: "150k Views",
            likes: "20k Likes",
            embedLink: "https://www.youtube.com/embed/dQw4w9WgXcQ",
        },
        {
            title: "Top 10 Exercises to Boost Your Athletic Performance.",
            time: "1 month ago",
            views: "200k Views",
            likes: "25k Likes",
            embedLink: "https://www.youtube.com/embed/dQw4w9WgXcQ",
        },
        {
            title: "The Science Behind Speed and Endurance Training.",
            time: "3 weeks ago",
            views: "100k Views",
            likes: "15k Likes",
            embedLink: "https://www.youtube.com/embed/dQw4w9WgXcQ",
        },
       
    ];
    return (
        <section className='py-10 bg-primary-300'>
            <div className='text-center'>
                <h3 className='h3'>Trending Topics</h3>
                <p className='text-primary-100 c5'>Watch our most trending videos of all time.</p>
            </div>
            <div className='max-w-8xl mx-auto w-full mt-[50px]'>
                <div className='grid grid-cols-1 gap-4 px-3 sm:grid-cols-2 lg:grid-cols-3 sm:px-0 '>
                    {trendingtopics.map((video, index) => (
                        <div key={index}>
                            <div className="relative mx-auto w-full rounded-xl overflow-hidden h-[380px]">
                                <iframe
                                    src={video.embedLink}
                                    title={video.title}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    className="w-full h-full"
                                ></iframe>
                            </div>
                            <div className="w-full p-4 mx-auto max-w-7xl">
                                <h6 className="font-semibold h8">{video.title}</h6>
                                <p className="b6 text-primary-100">
                                    {video.time} • {video.views} • {video.likes}
                                </p>
                            </div>
                        </div>
                    ))}
                   

                </div>
            </div>
        </section>
    )
}
const OurVideos = () => {

    const [videos, setVideos] = useState([
        {
          title: "What is PORTALIZED? How it can help athletes achieve their goals.",
          time: "2 months ago",
          views: "150k Views",
          likes: "20k Likes",
          embedLink: "https://www.youtube.com/embed/dQw4w9WgXcQ",
        },
        {
          title: "Top 10 Exercises to Boost Your Athletic Performance.",
          time: "1 month ago",
          views: "200k Views",
          likes: "25k Likes",
          embedLink: "https://www.youtube.com/embed/dQw4w9WgXcQ",
        },
        {
          title: "The Science Behind Speed and Endurance Training.",
          time: "3 weeks ago",
          views: "100k Views",
          likes: "15k Likes",
          embedLink: "https://www.youtube.com/embed/dQw4w9WgXcQ",
        },
      ]);
    
      const handleLoadMore = () => {
        setVideos((prevVideos) => [...prevVideos, ...prevVideos]);
      };
    return (
        <section className='py-10 bg-primary-300'>
            <div className='text-center'>
                <h3 className='h3'>Watch our Videos</h3>
                <p className='text-primary-100 c5'>Watch our most trending videos of all time.</p>
            </div>
            <div className='max-w-8xl mx-auto w-full mt-[50px]'>
                <div className='grid grid-cols-1 gap-4 px-3 sm:grid-cols-2 lg:grid-cols-3 sm:px-0'>
                    {   videos.map((video, index) => (
                        <div key={index}>
                            <div className="relative mx-auto w-full rounded-xl overflow-hidden h-[380px]">
                                <iframe
                                    src={video.embedLink}
                                    title={video.title}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    className="w-full h-full"
                                ></iframe>
                            </div>
                            <div className="w-full p-4 mx-auto max-w-7xl">
                                <h6 className="font-semibold h8">{video.title}</h6>
                                <p className="b6 text-primary-100">
                                    {video.time} • {video.views} • {video.likes}
                                </p>
                            </div>
                        </div>
                    ))}
                   

                </div>
            </div>
           <div className='flex items-center justify-center'>
                <button onClick={handleLoadMore}  className='flex items-center gap-4 px-8 py-4 mt-10 text-white bg-black border h7 rounded-xl mx-w-fit '>
                    Load More <span> <TfiReload/>    </span>
                </button>
           </div>
        </section>
    )
}