import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    token: null,
    isAuthenticated: false,
        refreshToken: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.isAuthenticated = false;
        },
          refreshAccessToken: (state, action) => {
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshToken; // ✅ Update refresh token
        }
    },
});

export const { login, logout ,refreshAccessToken} = userSlice.actions;
export default userSlice.reducer;