import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { MdStar } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Button from '../components/ui/button.js';
import { TfiReload } from 'react-icons/tfi';
import { useDispatch } from 'react-redux';
import { addToCart } from '../store/slices/cartSlice.js';
import { getProduct } from '../services/products.js';
import { getProductReviews, submitReview } from '../services/productReviews.js';
import { useAuth } from '../providers/AuthProvider.jsx'
import { toast } from 'react-toastify';

const ProductDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [product, setProduct] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth()

  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(5);
  const [submitting, setSubmitting] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false); // ✅ Control form visibility

  const handleAddToCart = async () => {
    if (!token) {
      navigate("/login");
      return;
    }
    if (!product) return;
    try {
      dispatch(addToCart({ productId: product.id, quantity: 1, token }));
    } catch (error) {
      console.error("Failed to add to cart:", error);
    }
  };

  const handleSubmitReview = async () => {
    if (!token) {
      navigate("/login");
      return;
    }
    if (!reviewText.trim()) return;

    try {
      setSubmitting(true);
      await submitReview(id, { rating, review_text: reviewText, token });

      const reviewData = await getProductReviews(id);
      setReviews(reviewData.results || []);

      // ✅ Hide review form after submitting
      setShowReviewForm(false);
      setReviewText("");
      setRating(5);
    } catch (error) {
      console.error("Failed to submit review:", error);
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("Failed to place order. Please try again.");
      }
    } finally {
      setSubmitting(false);
    }

   
  };

  const handleLoadMore = () => {
    const duplicatedReviews = [...reviews, ...reviews];
    setReviews(duplicatedReviews);
  }

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productData = await getProduct(id);
        setProduct(productData);
        setProductImage(productData.images?.[0] || "/default-product.jpg");
      } catch (err) {
        setError("Failed to fetch product details.");
      } finally {
        setLoading(false);
      }
    };
    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewData = await getProductReviews(id);
        setReviews(reviewData.results || []);
      } catch (err) {
        console.error("Failed to fetch reviews:", err);
      }
    };
    fetchReviews();
  }, [id]);

  if (loading) return <p className="text-center">Loading product details...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className='flex flex-col gap-y-[100px]'>
      <section className='w-full py-8 mx-auto max-w-7xl sm:py-16'>
        <div className='grid grid-cols-3 gap-x-20'>
          <div className='px-2 col-span-full md:col-span-2 md:px-0'>
            <div className='h-[500px] relative flex items-start justify-start rounded-2xl overflow-hidden'>
              <img src={productImage} className='object-cover w-full h-full' alt={product.name} />
            </div>
            <div className="flex gap-4 mt-4">
              {product?.images.map((image, index) => (
                <button
                  key={index}
                  onClick={() => setProductImage(image)}
                  className={`w-20 h-20 rounded-lg overflow-hidden border-2 ${productImage === image ? 'border-primary-200' : 'border-gray-300'}`}
                >
                  <img src={image} alt={`Thumbnail ${index + 1}`} className="object-cover w-full h-full" />
                </button>
              ))}
            </div>
          </div>
          <div className='flex flex-col gap-3 px-2 col-span-full md:col-span-1 md:px-0'>
            <h4 className='h4'>{product.name}</h4>
            <p className='flex items-center gap-2 b4'>{product.average_rating} <MdStar className='text-yellow-500' /> ({product.total_reviews >= 1 ? product.total_reviews : 0})</p>
            <h4 className='h4'>${product.price}</h4>
            <p className='b6 text-primary-100'>{product.description}</p>
            <Button onClick={handleAddToCart} className='!bg-black !text-white'>
              Add to Cart
            </Button>
          </div>
        </div>
      </section>

      {/* Reviews Section */}
      <section className="w-full px-2 mx-auto max-w-7xl md:px-0">
        <div className="flex items-center justify-between">
          <div>
            <h5 className="h5">Reviews</h5>
            <p className="flex items-center gap-2 b4">
              {product.average_rating || "No rating"} <MdStar className="text-yellow-500" /> ({product.total_reviews || 0})
            </p>
          </div>
          <button
            className="flex items-center gap-4 px-4 py-2 text-white bg-black border sm:py-4 sm:px-8 h8 rounded-xl"
            onClick={() => setShowReviewForm(true)} // ✅ Show form on click
          >
            Add Review
          </button>
        </div>

        {/* Review Form (Visible only when showReviewForm is true) */}
        {showReviewForm && (
          <div className="mt-6 rounded-lg">
            <h5 className="b5">Write a Review</h5>
            <select value={rating} onChange={(e) => setRating(Number(e.target.value))} className="p-2 text-white bg-black border rounded">
              {[5, 4, 3, 2, 1].map((r) => (
                <option key={r} value={r}>{r} Star{r > 1 ? "s" : ""}</option>
              ))}
            </select>
            <textarea
              className="w-full p-2 mt-2 text-white bg-black border rounded outline-none"
              rows="3"
              placeholder="Write your review..."
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
            />
            <Button onClick={handleSubmitReview} disabled={submitting} className="mt-2">
              {submitting ? "Submitting..." : "Submit Review"}
            </Button>
          </div>
        )}

        <div className="flex flex-col mt-10 gap-9">
          {reviews.length === 0 ? (
            <p className="text-center text-gray-500">No reviews yet.</p>
          ) : (
            reviews.map((review) => (
              <div key={review.id}>
                <div className="flex items-center gap-2">
                  <div className="w-14 h-14">
                    <img className="object-cover rounded-full" src={review.user_profile_picture || "/assets/user.avif"} alt={review.reviewerName} />
                  </div>
                  <div>
                    <p className="b5">{review.user_first_name} {review.user_last_name}</p>
                    <p className="flex items-center gap-1 b7">{review.rating} <MdStar className="text-yellow-500" /></p>
                  </div>
                </div>
                <p className="mt-6 text-primary-100 b6">{review.review_text}</p>
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  )
}

export default ProductDetail;